.form-input-with-suffix {
  .ant-input-wrapper {
    .ant-input-group-addon {
      border-color: #bebfbf;
      background: none !important;
      font-family: VisbyCF ExtraBold;
      color: #9E9E9E !important;
      font-weight: 700;
      font-size: 14px;
    }
    .ant-input {
      background: none !important;
      color: #616161 !important;
      padding: 10px 16px;
      border: solid 1px #bebfbf;
      &:hover,
      &:active {
        border-color: #594a9e;
        box-shadow: none;
      }
    }
  }
}
