.canned-message-select {
  width: 100%;
  margin-top: 10px;
  .ant-select-selector {
    // height: 40px !important;
    // border-radius: 8px !important;
    // border: 1px solid #00072b !important;
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}
.chat-message-bubble {
  max-width: 50%;
}