.ant-menu {
  &.ant-menu-dark {
    background: transparent !important;
    border-right: none;
    &:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: transparent !important;
      }
    }
    .ant-menu-submenu-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    .ant-menu-item {
      margin-bottom: 0 !important;
      margin-top: 0;
    }
    .ant-menu-item-selected::after {
      display: none;
    }
    .ant-menu-item-selected {
      span {
        font-weight: bold;
      }
    }
    .ant-menu-sub {
      background: transparent;
      .ant-menu-item {
        padding-left: 54px !important;
      }
    }
  }
  &.ant-menu-sub{
    background:  transparent !important;
  }
}

.sidebar-icon {
  width: 25px;
  height: 26px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 6px;
  margin-right: 15px;
}

.sidebar-icon.home {
  background-image: url(../../../../public/images/dashboard.png);
}

.ant-menu i {
  font-size: 1rem;
  font-weight: 100;
  text-align: center; 
}

.ant-menu span{
  // font-family: VisbyCF Regular;
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-size: 14px;
}

.ant-menu-title-content {
  color: rgba(255,255,255,0.70);
}

.ant-menu-item-selected, .ant-menu-item-selected .ant-menu-title-content, .ant-menu-title-content:hover {
  color: white;
} 