.business-sidebar {
  // background-color: #fff;
//  background: linear-gradient(180deg, #f7f7f7 41.67%, #fff 100%);
 // background: linear-gradient(180deg, #594A9E 41.67%, #7960D9 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 16px;
}
  
.ant-menu-item {
  padding: 0px 0px !important;
  height: auto !important;
}

.ant-menu-submenu-title{
  padding: 0px 0px !important;
  height: auto !important;
}

.hide-arrow .ant-menu-submenu-arrow {
  display: none;
}

.overflow-x-auto::-webkit-scrollbar {
  height: 0rem !important;
  width: 0rem !important;
}

.overflow-y-auto::-webkit-scrollbar {
  height: 0rem !important;
  width: 0rem !important;
}

.sidebar-menu{
  font-size: 13px;
}
