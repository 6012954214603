.form-select.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: #bebfbf;
    padding: 7px 10px;
    height: auto;
    border-radius: 4px;
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
  }
  }
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: #594a9e;
    box-shadow: none;
  }
}

.css-13cymwt-control{
  background: none !important;
  border: none !important;
}

.css-t3ipsp-control{
  background: none !important;
  border-color: #ffffff !important;
}

.css-1dimb5e-singleValue{
  color: inherit !important;
}

.css-1nmdiq5-menu{
  color: black !important;
}

.css-1jqq78o-placeholder{
  color: inherit !important;
}