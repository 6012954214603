// .color-select.ant-select:not(.ant-select-customize-input) {
//   .ant-select-selector {
//     border-color: #bebfbf;
//     height: 76px;
//     width: 81.1px;
//     .ant-select-selection-placeholder {
//       display: flex;
//       align-items: center;
//     }
//     .ant-select-selection-item {
//       display: flex;
//       align-items: center;
//     }
//   }
// }
// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
//   .ant-select-selector {
//     border-color: #594a9e;
//     box-shadow: none;
//   }
// }
.color-select-body{
  padding: 0.2em;
  border: 1px solid #E4E5E7;
  border-radius: 0.4em;
}
.color-select{
  height: 40px;
  width: 200px;
}

.color-picker-overlay {
  position: relative;
  z-index: 1;
  top: 100%;
  left: 0;
}