.slider-container {
  width: 100%;
  position: relative;
  .slick-slider {
    margin: 0 auto;
  }
  .slider-arrow-left {
    position: absolute;
    left: -30px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .slider-arrow-right {
    position: absolute;
    right: -30px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
