.css-1xc3v61-indicatorContainer{
    cursor: pointer;
}
  
.quill-dark {
    background: #242526 !important;
    color: white !important;
}

.quill-light {
    background: white !important;
    color: black !important;
}

.ql-toolbar.ql-snow{
    background: ghostwhite !important;
}

.quill-dark .ql-toolbar.ql-snow {
    background-color: #A9A9A9 !important;
}

.quill-dark .ql-container.ql-snow.ql-editor {
    color: #FFFFFF !important;
}

/* Placeholder color for light mode */
.quill-light .ql-editor::before {
    color: gray !important; /* Light gray placeholder */
}
  
/* Placeholder color for dark mode */
.quill-dark .ql-editor::before {
    color: #A9A9A9 !important; /* Medium gray placeholder for dark mode */
}
  
.custom-search-input {
    min-width: 100%;
    max-width: 100%;;
}

.ql-container {
    min-height: 100px;
}

  
