

@media only screen and (max-width: 766px) {
    .label-responsiveness {
        padding-left: 20%;
    }
    .responsive-block{
        padding-left:5%;
    }
}
@media only screen and (max-width: 400px) {
    .label-responsiveness {
        padding-left: 20%;
    }

    .responsive-block {
        padding-left: .5%;
    }
}

@media only screen and (max-width: 650px) {
    .pin-code-responsiveness {
        padding-left: 1%;
        
    }
}
