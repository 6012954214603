.navbar {
  .profile-menu,
  .lang-menu {
    top: 106px !important;
  }
}

.navbar {
  height: 64px;
}

@media (min-width: 768px){
  .navbar {
      height: 64px;
  }
  .navbar-arrow {
    font-size: 0.7rem;
  }
}
