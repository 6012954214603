.page-public-profile {
  .public-profile .header {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/bg-content.png");
  }
  .public-profile-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding-left: 24px;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0 24px 24px;
        }
      }
    }
  }
     .location-row {
       height: auto;
       width: inherit;
       margin: 20px;
       border-width: 1px;
       border-color: gray;
       background-color: white;
     }

     .banner-text {
        letter-spacing: 1px;
        font-weight: bolder;
     }
     .text-shadow-white {
        text-shadow: 1.5px 0px #fff;
     }
    .text-shadow-orange {
      text-shadow: 1.5px 0 #FFAD33;
    }
    .text-shadow-primary {
      text-shadow: 1.5px 0 #594A9E;
    }

    @media only screen and (min-width: 1020px) and (max-width: 1250px) {
      .media-text {
        font-size: 40px;
      }
    }
    @media only screen and (min-width: 201px) and (max-width: 490px) {
      .media-text {
        font-size: 16px;
      }
      .business-container{
        position: relative;
        right: 50px;
        width:200px;
      }

        .business-container p {
          width:220px;
        }

        .button-mobile {
          scale: 60%;
          position: relative;
          left:30px;
        }

    }
    @media only screen and (min-width: 0px) and (max-width: 200px) {
      .media-text {
        font-size: 16px;
      }

      .business-container {
        position: relative;
        right: 50px;
        width: 200px;
      }

      .business-container p {
        width: 150px;
      }

      .button-mobile {
        scale: 60%;
        position: relative;
        left: 30px;
      }

    }
}
