.ant-collapse.open-position-card-accordion {
  .ant-collapse-item-active {
    .ant-collapse-header {
      // background-color: #65b2e8;
      border-radius: 4px 4px 0 0 !important;
    }
  }
}

.ant-collapse-header-text{
  width: 100% !important;
}
