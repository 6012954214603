.admin-navbar {
  background: linear-gradient(180deg, #594A9E 41.67%, #7960D9 100%);
  font-size: 16px;
}

.admin-switch{
  display: block;
}

@media (max-width: 947px){
  .admin-switch{
    visibility: hidden;
  }
}

.admin-switch-nav{
  display: flex;
}

@media (min-width: 947px){
  .admin-switch-nav{
    display: none;
    visibility: hidden;
  }
}