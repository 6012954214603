.page-embed-code {
  .ant-tabs {
    .ant-tabs-nav {
      margin: 0;
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          flex: 1;
          padding: 0;
          border: solid 1px #f4f4f4;
          background: #E5E7EB;
          transition: all 0.2s ease;

          @media (prefers-color-scheme: dark) {
            background: #1e293b;
            border-color: #334155;
            
            &:hover {
              background: #334155;
            }
          }

          &.ant-tabs-tab-active {
            background: #fff;
            border-bottom-color: white;

            @media (prefers-color-scheme: dark) {
              background: #0f172a;
              border-bottom-color: #0f172a;
              border-top-color: #6366f1;
            }

            .ant-tabs-tab-btn {
              color: #4f46e5;

              @media (prefers-color-scheme: dark) {
                color: #818cf8;
              }
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      border-radius: 0px 3px 3px 3px;
      background: #fff;

      @media (prefers-color-scheme: dark) {
        background: #0f172a;
        border-color: #334155;
      }
    }
  }
}

// Dark mode overrides for Ant Design
.dark {
  .ant-tabs {
    .ant-tabs-tab {
      &:hover {
        color: #818cf8 !important;
      }
    }

    .ant-tabs-ink-bar {
      background: #818cf8;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #818cf8 !important;
    }
  }
  
  // Custom scrollbar for dark mode
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #1e293b;
  }

  ::-webkit-scrollbar-thumb {
    background: #475569;
    border-radius: 4px;
    
    &:hover {
      background: #64748b;
    }
  }
}