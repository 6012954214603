.ant-collapse.open-position-card-accordion {
  .ant-collapse-item {
    .ant-collapse-header {
      background-color: #F8F7FA !important;
      // border-radius: 4px 4px 0 0 !important;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      background-color: #594A9E !important;
      border-radius: 4px 4px 0 0 !important;
    }
  }
  .light{
    .ant-collapse-item-active {
      background-color: #616161 !important;
    }
    .ant-collapse-item{
      .ant-collapse-header {
        background-color: #F8F7FA;
        border-radius: 4px 4px 0 0 !important;
      }
    }
    .ant-collapse-ghost{
      .ant-collapse-item {
        .ant-collapse-content{
          background-color: #F8F7FA !important;
        }
      }
    }
  }
  .dark{
    .ant-collapse-item-active {
      background-color: #616161 !important;
    }
    .ant-collapse-ghost{
      .ant-collapse-item {
        .ant-collapse-content{
          background-color: #F8F7FA !important;
        }
      }
    }
  }
}
