.dashboard-table-collapse {
  background-color: #f5f5f4;
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0 30px 0 0;
      color: #88807B !important;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

/* Change the width when the horizontal scrollbar appears */
.overflow-x-auto::-webkit-scrollbar {
  height: 0.5rem; /* Set the height of the scrollbar */
}

.overflow-x-auto::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb */
  border-radius: 2rem; /* Set the border radius of the thumb */
}

/* When the scrollbar appears */
.overflow-x-auto:hover::-webkit-scrollbar-thumb {
  width: 0.75rem; /* Set the width of the thumb */
}

/* When the scrollbar disappears */
.overflow-x-auto:hover::-webkit-scrollbar-thumb {
  width: 0.5rem; /* Set the width of the thumb */
}

.ant-collapse {
  background: none !important;
}