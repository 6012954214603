@import "~antd/dist/antd.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For WebKit-based browsers */
* {
  scrollbar-width: 2px; /* Firefox does not support this directly, using thin instead */
  scrollbar-color: #909090 #f1f1f1; /* Scrollbar thumb (black) and track color */
}

/* Webkit Browsers */
::-webkit-scrollbar {
  width: 2px; /* Set the scrollbar width */
  height: 2px; /* For horizontal scrollbar, if needed */
}

::-webkit-scrollbar-thumb {
  background-color: #000000; /* Scrollbar thumb color (black) */
  border-radius: 10px; /* Optional: to give the scrollbar thumb rounded edges */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

/* @font-face {
  font-family: "Newake Regular";
  src: local("Newake Regular"),
    url("./assets/fonts/Newake-Font-Regular.woff2") format("truetype");
}
@font-face {
  font-family: "VisbyCF Thin";
  src: local("VisbyCF Thin"),
    url("./assets/fonts/VisbyCF-Thin.woff2") format("truetype");
}
@font-face {
  font-family: "VisbyCF Regular";
  src: local("VisbyCF Regular"),
    url("./assets/fonts/VisbyCF-Regular.woff2") format("truetype");
}
@font-face {
  font-family: "VisbyCF DemiBold";
  src: local("VisbyCF DemiBold"),
    url("./assets/fonts/VisbyCF-DemiBold.woff2") format("truetype");
}
@font-face {
  font-family: "VisbyCF ExtraBold";
  src: local("VisbyCF ExtraBold"),
    url("./assets/fonts/VisbyCF-ExtraBold.woff2") format("truetype");
}
@font-face {
  font-family: "VisbyCF Bold";
  src: local("VisbyCF Bold"),
    url("./assets/fonts/VisbyCF-Bold.woff2") format("truetype");
} */

@font-face {
  font-family: "Brockmann Regular";
  src: local("Brockmann Regular"),
    url("./assets/fonts/brockmann-regular-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann RegularItalic";
  src: local("Brockmann RegularItalic"),
    url("./assets/fonts/brockmann-regularitalic-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann Bold";
  src: local("Brockmann Bold"),
    url("./assets/fonts/brockmann-bold-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann BoldItalic";
  src: local("Brockmann BoldItalic"),
    url("./assets/fonts/brockmann-bolditalic-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann SemiBold";
  src: local("Brockmann SemiBold"),
    url("./assets/fonts/brockmann-semibold-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann SemiBoldItalic";
  src: local("Brockmann SemiBoldItalic"),
    url("./assets/fonts/brockmann-semibolditalic-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann Medium";
  src: local("Brockmann Medium"),
    url("./assets/fonts/brockmann-medium-webfont.woff2") format("truetype");
}
@font-face {
  font-family: "Brockmann MediumItalic";
  src: local("Brockmann MediumItalic"),
    url("./assets/fonts/brockmann-mediumitalic-webfont.woff2") format("truetype");
}