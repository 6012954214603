
@media (max-width: 765px) {
  .custom-screen-size {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 510px) {
  .custom-screen-size {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
