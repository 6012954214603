.brHorP{
    color : #000000 !important;
}

.huPukO{
    background-color: white !important;
}
.gSZyCq{
    background-color: white !important;
}
.hgcfuV{
    background-color: white !important;
}
.fknqMm{
    background-color: white !important;
}
.hxnZkt{
    background-color: white !important;
}

.custom-doc-viewer {
    height: 1000px !important;
  }
  
  @media (max-width: 768px) {
    .custom-doc-viewer {
      height: 500px !important;
    }

}

.custom-flex-direction {
    flex-direction: row !important;
}

.button-position {
    margin-top: 12px;
    margin-right: 12px;
}

@media (max-width: 343px){
    .custom-flex-direction {
        flex-direction: column !important;
    }

    .button-position {
        margin-top: 12px;
        margin-right: 0px;
    }   
}

@media (min-width: 992px) and (max-width: 1662px) {
    .custom-flex-direction {
        flex-direction: column !important;
    }
    
    .button-position {
        margin-top: 12px;
        margin-right: 0px;
    }
}

@media (min-width: 1662px) {
    .custom-flex-direction {
        flex-direction: row !important;
    }
}
