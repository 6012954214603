/* @layer base {
  @font-face {
    font-family: Newake Regular;
    src: url("./assets/fonts/Newake-Font-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF Thin;
    font-size: 200;
    src: url("./assets/fonts/VisbyCF-Thin.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF Regular;
    font-size: 300;
    src: url("./assets/fonts/VisbyCF-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF Bold;
    font-size: 400;
    src: url("./assets/fonts/VisbyCF-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF Bold;
    font-size: 400;
    src: url("./assets/fonts/VisbyCF-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF DemiBold;
    font-size: 500;
    src: url("./assets/fonts/VisbyCF-DemiBold.woff2") format("woff2");
  }
  @font-face {
    font-family: VisbyCF ExtraBold;
    font-size: 600;
    src: url("./assets/fonts/VisbyCF-ExtraBold.woff2") format("woff2");
  }
  /* @font-face {
    font-family: Gilroy Light;
    font-size: 300;
    src: url("./assets/fonts/Gilroy-Light.otf") format("truetype");
  } */
/* 
  h1,
  .h1 {
    @apply font-extrabold font-bold text-6xl leading-[60px];
  }

  h2,
  .h2 {
    @apply font-extrabold text-[38px] leading-[60px];
  }

  h3,
  .h3 {
    @apply font-extrabold text-[32px] leading-[38.4px];
  }

  h4,
  .h4 {
    @apply font-bold text-[24px] leading-[33.6px];
  }

  h5,
  .h5 {
    @apply font-bold text-xl leading-6;
  }

  h6,
  .h6 {
    @apply font-bold text-[14px] leading-[18px];
  }

  p {
    @apply font-normal text-base leading-[20px];
  }

  .subtitle {
    @apply font-light text-lg leading-[23.4px];
  }

  .subtitle2 {
    @apply font-light text-base leading-[20.8px];
  }

  .body {
    @apply font-light text-base leading-[27.2px];
  }

  .container {
    @apply max-w-[1190px] w-full mx-auto;
  }

  .button,
  button {
    @apply font-normal text-[18px] leading-[23px];
  }

  .small {
    @apply font-light text-[10px] leading-[11.9px];
  }

  .title {
    @apply text-[14px] leading-[16px];
  }

  .tbl-cell {
    @apply whitespace-nowrap flex items-center px-3 min-h-[48px];
  }
} */
/* 14
18
24
32
38 */

body {
  margin: 0;
  /* font-family: 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: VisbyCF Regular; */
  font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol,
    "Noto Color Emoji";
  color: #111922;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ededed;
}
#root {
  height: 100%;
}
a {
  transition: 0s;
}
button {
  font-size: 14px;
  font-style: normal;
  font-family: inherit;
}
.preview-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
p {
  font-size: inherit;
}
.job-opening-image {
  border-radius: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.calendar-dark::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.tag-label {
  font-family: "Roboto", "Arial", sans-serif !important;
  font-weight: 400;
  line-height: 2rem;
}

.tooltip {
  position: absolute;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 14px;
  width: 60px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 1;
  padding: 5px 0;
  position: absolute;
  top: 90%;
  left: 100%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (min-width: 947px) {
  .shadow-bottom {
    box-shadow: 2px 2px 2px -2px rgba(0, 0, 0, 0.1),
      2px 4px 2px -1px rgba(0, 0, 0, 0.06) !important;
  }
}

@keyframes blink {
  50% {
    background-color: #cbc3e3;
  }
}

.blink {
  animation: blink 1s infinite;
}

@media (max-width: 300px) {
  .chat-window-mobile {
    width: 220px;
  }
}

@media (min-width: 400px) {
  .job-opening-mobile {
    flex-direction: row;
  }
}
