.message-container {
  height: 70vh;
}

/* Works on Firefox */
// * {
//   scrollbar-color: #000000;
//   scrollbar-width:initial;
// }

// // /* Works on Chrome, Edge, and Safari */
// body::-webkit-scrollbar {
//   width: 0.5px;
// }

// body::-webkit-scrollbar-track {
//   background: #000000;
// }

// body::-webkit-scrollbar-thumb {
//   background-color: #6e55ce;
//   border-radius: 2px;
//   border: 1px solid #000000;
// }
