
.PhoneInputInput:focus-visible {
  outline: none !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  padding-top: 1.25rem !important;
  padding-bottom: 1.2rem !important;
  background: none !important;
}

.react-tel-input .flag-dropdown{
background: none !important;
}

.react-tel-input .country-list{
background: white !important;
color: black !important;
}