.mobile-side-menu {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 0;
        }
      }
    }
  }
}

.ant-drawer-content {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.page-content {
  //background-image: url(../../../../public/images/bg-dashboard-vector.svg);
  background-size: 100% auto;
  background-position-y: bottom;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.business-navbar{
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.gradient-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  background: linear-gradient(to bottom, from-primary-100, via-purple-100, to-white);
  z-index: -1; 
}