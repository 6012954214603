.rangecontainer{
    visibility: hidden !important;
    width: 0px !important;
}

.inputDate{
    border-radius: 4px !important;
}

.fromDateHourContainer{
    text-align: center !important;
}

#Hour_start, #Minutes_start, #Hour_end, #Minutes_end {
    border-radius: 4px !important;
}

#MonthSelector_start, #YearSelector_start, #MonthSelector_end, #YearSelector_end {
    border-radius: 4px !important;
}

.select {
    padding: 0px !important;
}

.monthYearContainer {
    margin-top: 4px !important;
}